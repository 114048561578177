import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truefalse'
})
export class TruefalsePipe implements PipeTransform {

  transform(valor:number, tipo:number): unknown {

    //Tipo 1 es para verdadero y falso
    if(tipo==1){// si es 1 evalua V o F
      if(valor==1){ //Si es 1
        return 'Activo';  // Es verdadero
      }else{ //Caso contrario
        return 'Inactivo'; //Es falso
      }
    }
  }

}
