                        <!-- start page title -->
                        <div class="row">
                          <div class="col-12">
                              <div class="page-title-box">
                                  <div class="page-title-right">
                                      <ol class="breadcrumb m-0">
                                          <li class="breadcrumb-item"><a href="javascript: void(0);">Hyper</a></li>
                                          <li class="breadcrumb-item"><a href="javascript: void(0);">Base UI</a></li>
                                          <li class="breadcrumb-item active">{{ titulo }}</li>
                                      </ol>
                                  </div>
                                  <h4 class="page-title">{{ titulo }}
                                    <!-- <li *ngFor="let menu of _topbar.menu">{{ menu.titulo }}</li> -->


                                  </h4>
                              </div>
                          </div>
                      </div>
                      <!-- end page title -->
