    <!-- Warning Alert Modal -->
    <div id="info-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-sm">
          <div class="modal-content">
              <div class="modal-body p-4">
                  <div class="text-center">
                      <i class="dripicons-information h1 text-info"></i>
                      <h4 class="mt-2"><!-- {{titulo_error}} --></h4>
                      <p class="mt-3"><!-- {{descripcion_error}} --></p>
                      <button type="button" class="btn btn-info my-2" data-dismiss="modal"> Continuar </button>
                  </div>
              </div>
          </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

    <!-- Warning Alert Modal -->
    <div id="success-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-sm">
          <div class="modal-content">
              <div class="modal-body p-4">
                  <div class="text-center">
                      <i class="dripicons-information h1 text-info"></i>
                      <h4 class="mt-2"><!-- {{titulo_error}} --></h4>
                      <p class="mt-3"><!-- {{descripcion_error}} --></p>
                      <button type="button" class="btn btn-info my-2"  data-dismiss="modal"  > Continuar </button> <!-- (click)="redireccion()" -->
                  </div>
              </div>
          </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
