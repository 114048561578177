import { Component, OnInit } from '@angular/core';

// IMPORTAMOS SERVICIOS
import { TopbarService } from '../../services/topbar.service';
import { UsuarioService } from '../../../service/usuario.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  constructor
  (
    public _topbar: TopbarService,
    public _usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
  }
}
