import { Injectable } from '@angular/core';

import {URL_SERVICIOS, URL_UPLOAD} from '../../config/config'

@Injectable({
  providedIn: 'root'
})
export class SubirArchivoService {

  constructor() { }

  subirArchivo(archivo: File, tipo:string, id:string, token:string, usuario_id:string){

    return new Promise((resolve, reject) => {

      let formData  =  new FormData();
      let xhr       =  new XMLHttpRequest();

      formData.append('imagen',archivo,archivo.name);

      xhr.onreadystatechange = function(){

        if(xhr.readyState === 4){
          if(xhr.status===200){
            
            resolve( JSON.parse(xhr.response) );
          }else{
            
            reject( xhr.response );
          }
        }
      }

      let url = URL_SERVICIOS + URL_UPLOAD + '?token=' + token + '&usuario_id=' + usuario_id ;
      xhr.open('POST',url,true);
      xhr.send(formData);
    });
  }
}
