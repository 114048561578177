<div class="topnav shadow-sm">
  <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
          <div class="collapse navbar-collapse" id="topnav-menu-content">
              <ul class="navbar-nav">
                 <li *ngFor="let menu of _topbar.menu" class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-dashboards" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="{{menu.icono}} mr-1"></i>{{menu.titulo}} <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu" aria-labelledby="topnav-dashboards">
                          <a *ngFor="let submenu of menu.submenu" routerLinkActive="active" [routerLink]="[submenu.url]" class="dropdown-item">{{submenu.titulo}}</a>
                      </div>
                  </li>
              </ul>
          </div>
      </nav>
  </div>
</div>
