                <!-- Footer Start -->
<!--                 <footer class="footer">
                  <div class="container-fluid">
                      <div class="row">
                          <div class="col-md-6">
                              2020 © CDE Region Lempa
                          </div>
                          <div class="col-md-6">
                              <div class="text-md-right footer-links d-none d-md-block">
                                  <a href="javascript: void(0);">About</a>
                                  <a href="javascript: void(0);">Support</a>
                                  <a href="javascript: void(0);">Contact Us</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </footer> -->


              <!-- end Footer -->
<div class="row pt-5">
              <footer class="footer bg-white">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="text-md-left footer-links d-none d-md-block">
                        <i class="uil-copyright"></i> 2020 | <a target="_blank" href="https://www.cdemipymerl.org/"> <i class="dripicons-web"> </i> CDE MIPYME Región Lempa </a>
                      </div>
                    </div>
                    <div class="col-md-2">
                        <div class="text-md-center footer-links d-none d-md-block">
                            <a target="_blank" href="https://www.facebook.com/cdemipymerl"> <i class="uil uil-facebook-f"></i></a>
                            <a target="_blank" href="https://www.instagram.com/cdemipymerl/"> <i class="uil uil-instagram"></i> </a>
                            <a target="_blank" href="https://twitter.com/cdemipymerl/"> <i class="uil uil-twitter"></i></a>
                        </div>
                    </div>
                    <div class="col-md-5 row d-flex justify-content-end">
                      <div class="text-md-right footer-links d-none d-md-block">
                        <a routerLink="/cooperantes"  target="_blank"> Cooperantes y financiadores </a> |  Términos y condiciones
                      </div>
                    </div>
                </div>
                </div>
              </footer>
</div>
