import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { UsuarioService } from 'src/app/service/usuario.service';


@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {

  constructor(
    public _usuarioService: UsuarioService,
    public router:Router
  ){}

  canActivate(){

     if( this._usuarioService.estaLogueado() ){
      
      this.router.navigate(['/inicio/principal'])
      return false;
    }else{
      
      return true;
    }
  }

  canActivateChild(){
    if( !this._usuarioService.estaLogueado() ){
      this.router.navigate(['/login'])
      return false;
    }else{
      return true;
    }
  }

}
