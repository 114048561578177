import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TopbarService {

  menu: any = [
    {
      nombre:  localStorage.getItem('Nombre_completo'),
      empresa: localStorage.getItem('nombre_empresa'),
      icono:   'uil-dashboard',
      posicion:   'uil-dashboard',
      submenu: [
        {titulo: 'Perfil',url: 'perfil',icono: 'mdi-account-circle'},
        /* {titulo: 'Salir',url: '/login',icono: 'mdi-logout'}, */
      ]
    },
  ];

  constructor() { }
}
