import { Injectable } from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, filter, map } from 'rxjs/operators';
import {throwError} from 'rxjs';
import Swal from 'sweetalert2';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  constructor(
    private _usuarioService:UsuarioService,
    public _router: Router,
  ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>{
    if(!this._usuarioService.token || !this._usuarioService.usuario.usuario_id){
      var token: string     = '';
      var usuario_id:string  = '';
    }else{
      var token: string     = this._usuarioService.token;
      var usuario_id:string  = this._usuarioService.usuario.usuario_id;
    }

    const cabezera = req.clone({
      setHeaders:
        {
          'token'       : token,
          'asesor_id'   : '',
          'usuario_id'  : usuario_id,
          'tipo_sesion' : 'usuario',

        }
    });


    return next.handle(cabezera).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if(event.body._error ==true && event.body._error_codigo=="1-1-1"){
            this._usuarioService.logout();
          }
          return event;
        }
      })
    );
    
    
    /* return next.handle(cabezera).pipe((resp:any)=>{
      console.log('Desde inyector: ',resp);
      return resp;
    }) */

    
    /* .pipe(
    catchError(respuesta => {
      return throwError(respuesta);
    })); */
  }
}
