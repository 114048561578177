import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paso-costo-por-adquisicion',
  templateUrl: './paso-costo-por-adquisicion.component.html',
  styleUrls: ['./paso-costo-por-adquisicion.component.css']
})
export class PasoCostoPorAdquisicionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
