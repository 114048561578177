import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { NgSelectConfig } from '@ng-select/ng-select';

import { registerLocaleData } from '@angular/common';
import localeHN from '@angular/common/locales/es-HN';
registerLocaleData(localeHN);

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { SharedModule } from './pages/shared/shared.module';
import { PasoCostoPorAdquisicionComponent } from './src/app/pages/modulo/atencionemprendimiento/nuevasolicitudstencionemprendimiento/paso-analisisfinanciero/paso-costo-por-adquisicion/paso-costo-por-adquisicion.component';
import { AuthInterceptorService } from './service/auth-interceptor.service';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PasoCostoPorAdquisicionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'es-HN'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
