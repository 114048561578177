//ACCIONES
let font:string						=		' font-17 pl-1 ';
export const ICON_LOGIN				=		font + 'uil uil-sign-out-alt';
export const ICON_LOGOUT			=		font + 'uil uil-sign-in-alt';

export const ICON_NUEVO				=		font + 'uil uil-file-plus-alt';//uil-file-plus-alt
export const ICON_GUARDAR			=		font + 'uil uil-file'
export const ICON_ACTUALIZAR		=		font + 'uil uil-sync';
export const ICON_CANCELAR			=		font + 'uil uil-times-circle';
//export const ICON_RETORNAR			=		font + '';
export const ICON_EDITAR			=		font + 'uil uil-file-edit-alt'; //uil-sign-in-alt



let font_icon:string				=		' font-17 pl-1 ';
export const ICON_AGREGAR_ITEM		=		font_icon + 'uil uil-plus-circle'; //'uil-plus';
export const ICON_EDITAR_ITEM		=		font_icon + 'uil uil-edit';
export const ICON_ELIMINAR_ITEM		=		font_icon + 'uil uil-trash-alt';


export const ICON_ARCHIVO_ADJUNTO 	=		font_icon + 'uil uil-link-alt';
export const ICON_CONFIGURACION		=		font_icon + 'uil uil-cog';
export const ICON_SIGUIENTE			=		font_icon + 'uil uil-arrow-circle-right';
export const ICON_ANTERIOR			=		font_icon + 'uil uil-arrow-circle-left';
export const ICON_RETORNAR			=		font_icon + 'uil uil-corner-up-left-alt';
export const ICON_AREGAR_USUARIO	=		font_icon + 'uil uil-user-plus';
export const ICON_BUSCAR			=		font_icon + 'uil uil-search';
export const ICON_DESCARGAR			=		font_icon + 'uil uil-cloud-download';

export const ICON_EVALUACION		=		font_icon + 'uil-award';




//ESTADOS
export const ICON_LINK				=		font + 'uil uil-link-h';
export const ICON_LIKE				=		font + 'uil uil-thumbs-up';
export const ICON_PREGUNTA			=		font + 'uil uil-question-circle';
export const ICON_FAVORITO			=		font + 'uil uil-favorite';
export const ICON_INFORMACION		=		font + 'uil uil-info-circle';
export const ICON_EXCLAMACION		=		font + 'uil uil-exclamation-triangle';
export const ICON_CALENDARIO		=		font + 'uil uil-calender'
export const ICON_LISTO_CIRCULO 	=		font + 'uil uil-check-circle'; //  (CIRCULO)
export const ICON_LISTO_SIN_CIRCULO	=		font + 'uil uil-check';	//	   (SIN CIRCULO)
export const ICON_CAMPANA			=		font + 'uil uil-bell';
export const ICON_HORA				=		font + 'uil uil-clock';
export const ICON_CONECTAR			=		font + 'uil uil-headphones';

//ARCHIVOS
export const ICON_SALIR				=		font + 'uil uil-sign-out-alt'
export const ICON_SUBRIR_ARCHIVO	=		font + 'uil uil-upload';

export const ICON_USUARIO			=		font + 'uil uil-user-circle';




