import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ContenidoComponent } from './contenido/contenido.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SliderComponent } from './slider/slider.component';
import { TopbarComponent } from './topbar/topbar.component';
import { HttpClientModule } from '@angular/common/http';
import { PipesModule } from './pipes/pipes.module';
import { ImagenPipe } from './pipes/imagen.pipe';
import { AlertasComponent } from './alertas/alertas.component';

@NgModule({
  declarations: [BreadcrumbComponent, ContenidoComponent, FooterComponent, NavbarComponent, SliderComponent, TopbarComponent, AlertasComponent],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    PipesModule,
  ],
  exports: [BreadcrumbComponent, ContenidoComponent, FooterComponent, NavbarComponent, SliderComponent, TopbarComponent,ImagenPipe,AlertasComponent],
})
export class SharedModule { }
