                    <!-- Topbar Start -->
                    <div class="navbar-custom topnav-navbar topnav-navbar-light border-bottom mb-2 rounded">
                      <div class="container-fluid">

                          <!-- LOGO -->
                          <a [routerLink]="['/inicio/principal']" class="topnav-logo">
                              <span class="topnav-logo-lg">
                                  <img src="assets/images/logo_topbar_horizontal.png" alt="" height="36">
                              </span>
                              <span class="topnav-logo-sm">
                                  <img src="assets/images/logo_mini.png" alt="" height="36">
                              </span>
                          </a>

                          <ul class="list-unstyled topbar-right-menu float-right mb-0">
                            <!-- <li class="d-flex align-items-end">
                              <div class="text-center text-muted footer-links " >
                                <a routerLink="/cooperantes"  target="_blank"> Cooperantes y financiadores </a>
                              </div>
                            </li> -->


<!--                               <li class="dropdown notification-list">
                                  <a class="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" href="#" id="topbar-notifydrop" role="button" aria-haspopup="true" aria-expanded="false">
                                      <i class="dripicons-bell noti-icon"></i>
                                      <span class="noti-icon-badge"></span>
                                  </a>
                                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg" aria-labelledby="topbar-notifydrop">

                                      <div class="dropdown-item noti-title">
                                          <h5 class="m-0">
                                              <span class="float-right">
                                                  <a href="javascript: void(0);" class="text-dark">
                                                      <small>Clear All</small>
                                                  </a>
                                              </span>Notification
                                          </h5>
                                      </div>

                                      <div style="max-height: 230px;" data-simplebar>
                                          <a href="javascript:void(0);" class="dropdown-item notify-item">
                                              <div class="notify-icon bg-primary">
                                                  <i class="mdi mdi-comment-account-outline"></i>
                                              </div>
                                              <p class="notify-details">Caleb Flakelar commented on Admin
                                                  <small class="text-muted">1 min ago</small>
                                              </p>
                                          </a>

                                          <a href="javascript:void(0);" class="dropdown-item notify-item">
                                              <div class="notify-icon bg-info">
                                                  <i class="mdi mdi-account-plus"></i>
                                              </div>
                                              <p class="notify-details">New user registered.
                                                  <small class="text-muted">5 hours ago</small>
                                              </p>
                                          </a>


                                          <a href="javascript:void(0);" class="dropdown-item notify-item">
                                              <div class="notify-icon bg-primary">
                                                  <i class="mdi mdi-comment-account-outline"></i>
                                              </div>
                                              <p class="notify-details">Caleb Flakelar commented on Admin
                                                  <small class="text-muted">4 days ago</small>
                                              </p>
                                          </a>

                                          <a href="javascript:void(0);" class="dropdown-item notify-item">
                                              <div class="notify-icon bg-info">
                                                  <i class="mdi mdi-heart"></i>
                                              </div>
                                              <p class="notify-details">Carlos Crouch liked
                                                  <b>Admin</b>
                                                  <small class="text-muted">13 days ago</small>
                                              </p>
                                          </a>
                                      </div>

                                      <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all">
                                          View All
                                      </a>

                                  </div>
                              </li> -->


                              <li *ngFor="let menu of _topbar.menu" class="dropdown notification-list">
                                  <a  class="nav-link dropdown-toggle nav-user arrow-none mr-0 border-bottom" data-toggle="dropdown" id="topbar-userdrop" href="#" role="button" aria-haspopup="true"
                                      aria-expanded="false">
                                      <span class="account-user-avatar"><!--  dripicons-user -->
                                          <img [src]="_usuarioService.usuario.imagen_profile | imagen:'51'" alt="user-image" class="rounded-circle">
                                      </span>
                                      <span>
                                          <span class="account-user-name">{{_usuarioService.usuario.nombre_completo}}</span>
                                          <span class="account-position">{{_usuarioService.usuario.tipo_solicitud | titlecase }}</span>
                                      </span>
                                  </a>



                                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown " aria-labelledby="topbar-userdrop">
                                      <!-- item-->
                                      <div class=" dropdown-header noti-title">
                                          <h6 class="text-overflow m-0"> ¡BIENVENIDO!</h6>
                                      </div>

                                      <!-- item-->
                                      <a *ngFor="let submenu of menu.submenu" routerLinkActive="active" [routerLink]="[submenu.url]" class="dropdown-item notify-item">
                                          <i class="mdi {{submenu.icono}} mr-1"></i>
                                          <span>{{submenu.titulo}}</span>
                                      </a>

                                      <a (click)="_usuarioService.logout()" class="dropdown-item notify-item " style="cursor: pointer;">
                                        <i class="mdi mdi-logout mr-1"></i>
                                        <span>Salir </span>
                                      </a>

                                  </div>
                              </li>

                          </ul>
                          <!-- <a class="navbar-toggle"  data-toggle="collapse" data-target="#topnav-menu-content">
                              <div class="lines">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                              </div>
                          </a> -->
                      </div>
                  </div>
                  <!-- end Topbar -->
