import { Injectable } from '@angular/core';
import { usuarioModelo, archivoUsuarioModelo } from '../model/usuario.model';

import { HttpClient } from '@angular/common/http'
import {map} from 'rxjs/operators'

import { URL_SERVICIOS, URL_UPLOAD } from 'src/app/config/config';
import { Router } from '@angular/router';
import { SubirArchivoService } from '../pages/services/subir-archivo.service';
import { archivoSoporteModelo } from '../model/curso.model';

@Injectable({
  providedIn: 'root'
})

export class UsuarioService {
  //DECLARAMOS LAS VARIABLES INICIALES

  public usuario: usuarioModelo;
  public token  :    string;

  aceptatyc:boolean;


  public area_cursos_actual:string="";
  //INYECTAMOS EL HTTPCLIENT Y CARGAMOS LAS VARIABLES INICIALES
  constructor
  (
    public http:HttpClient,
    public router: Router,
    public _subirArchivoService: SubirArchivoService
  ) {
    this.cargarStorage();
  }

  // VALIDAMOS SI LA VARIABLE ESTA LOGUEADA
  estaLogueado(){
    //return(this.token.length>5) ? true:false;
    if(this.token.length>5 && this.usuario.usuario_id.length>0 && this.usuario.email.length>5){
      return true
    }else{
      localStorage.clear();
      return false;
    }
  }

  // CARGAMOS EL STORAGE PARA VALIDAR SI ESTAN SETEADAS
  cargarStorage(){
    if (localStorage.getItem('token')  && localStorage.getItem('usuario') ){
      this.token    = localStorage.getItem('token');
      this.usuario  = JSON.parse(localStorage.getItem('usuario'));
    }else{
      this.token     = '';
      this.usuario   = null;
    }
  }

  guardarStorage(token:string, usuario:usuarioModelo) {
    this.usuario=usuario;
    this.token = token;

    localStorage.setItem( 'token', token );
    localStorage.setItem( 'usuario', JSON.stringify(this.usuario) );
  }

  // FUNCION PARA LOGIN DE USUARIO
  login(usuario:usuarioModelo){
    //Tomamos las variables que se utilizaran
    let url = URL_SERVICIOS + 'usr/login';

    return this.http.post(url,usuario)
    /* .pipe(map((resp:any) => {
        
        this.guardarStorage(resp._token,resp._contenido);
        return true;
      }
    )) */
  }

  aceptar(valor:boolean){
    this.aceptatyc = valor;
  }

  crearUsuario (usuario:usuarioModelo){
    let url = URL_SERVICIOS + 'usr/usuarios';
    return this.http.post(url,usuario);
  }

  logout(){
    this.token='';
    this.usuario.nombre_completo='';
    this.usuario.nombre_empresa='';
    this.usuario.imagen_profile='';

    localStorage.clear();
   /*  this.usuario = null; */

    this.router.navigate(['/login']);
  }

  actualizarUsuario(usuario:usuarioModelo){
    let url = URL_SERVICIOS + 'usr/usuarios' + '/' + usuario.usuario_id + '?token=' + this.token;

    return this.http.put(url,usuario);
  }

  cambiarContrasena(usuario_id:string,contrasena:string,ncontrasena:string,rncontrasena:string){
    let url = URL_SERVICIOS + 'usr/usuarios' + '/' + usuario_id   + '/cambiarcontrasena' + '?token=' + this.token;
    return this.http.put(url,{contrasena,ncontrasena,rncontrasena});
  }

  reestablecerContrasena(email:string){
    let url = URL_SERVICIOS + 'usr/reset/reestablecercontrasena';
    return this.http.put(url,{email});
  }

  cambiarImagen(archivo:File,id:string){
    this._subirArchivoService.subirArchivo(archivo,'perfil',id,this.token,this.usuario.usuario_id)
        .then( (resp:any) =>{
          this.usuario.imagen_profile=resp._contenido.imagen_profile;
/*           this.usuario.imagen_profile=JSON.parse(resp._contenido.imagen_profile);
 */        })
        .catch( (resp:any) => {

        })
  }

  uploadFileToActivity(fileToUpload: File, token:string, tipo:string ){
    let url = URL_UPLOAD  + 'upload.php'  + '?token=' + token + '&tipo=' + tipo;

   const endpoint = url;
   const formData: FormData = new FormData();

   formData.append('file', fileToUpload, fileToUpload.name);
   return this.http.post(endpoint, formData)
     .pipe(map((resp:any) => {
       return resp;
      }))
  }

  guardardbArchivoSoporteTema(archivo:archivoUsuarioModelo,usuario_id:string,token:string){
  let url = URL_SERVICIOS + 'usr/usuarios' + '/' + usuario_id   + '/archivos' + '?token=' + token;
  return this.http.post(url, archivo);
  }

  listarArchivoSoporte(usuario_id:string, token:string){
    let url = URL_SERVICIOS + 'usr/usuarios' + '/' + usuario_id   + '/archivos' + '?token=' + token;
    return this.http.get(url);
  }

  eliminarArchivoSoporte(usuario_id:string, documento_id:string ,token:string){
    let url = URL_SERVICIOS + 'usr/usuarios' + '/' + usuario_id   + '/archivos/' + documento_id + '?token=' + token;
    return this.http.delete(url);
  }

  verificarEventosPendientes(usuario_id:string, token:string){
    let url = URL_SERVICIOS + 'usr/usuarios' + '/' + usuario_id + '/verificareventospendientes' + '?token=' + token;
    return this.http.get(url);
  }
}
