  <div class="auth-fluid">
    <div class="auth-fluid-form-box">
        <div class="align-items-center d-flex h-100">
            <div class="card-body"  *ngIf="mostrarLogin!=true">

              <div class="text-center">
                <a href="index.html">
                  <span><img src="./assets/images/logo.png" alt="" height="80"></span>
                </a>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
              </div>

              <h4 class="mt-0">Restablecer la contraseña</h4>
              <p class="text-muted mb-4">Ingrese la dirección de correo electrónico con la cual se registró, y recibirá un nuevo correo electrónico con una nueva contraseña.</p>

              <div class="form-group mb-3">
                <label for="emailaddress">Correo electrónico</label>
                <input class="form-control" type="email" id="emailaddress" required="" placeholder="Ingrese la dirección de correo electrónico con la cual se registro" [(ngModel)]="email">
              </div>
              <div class="form-group mb-0 text-center">
                  <button [disabled]="deshabilitarReestablecer" class="btn btn-primary btn-block" type="button" (click)="reestablecerContrasena()">
                      <span *ngIf="deshabilitarReestablecer==true" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"> </span>
                      <i class="mdi mdi-lock-reset" ></i> Restablecer la contraseña
                  </button>
              </div>

















              <footer class="footer footer-alt">
                <p class="text-muted">Regresar a <a href="javascript:void(0);" (click)="mostrarReestablecerContrasena(true)" class="text-muted ml-1"><b> <i class="{{icon_login}}"></i> iniciar sesión </b></a></p>
              </footer>

            </div>
            <div class="card-body" *ngIf="mostrarLogin==true">

              <!-- Logo -->
              <div class="text-center">
                <a href="index.html">
                  <span><img src="assets/images/logo.png" alt="" height="80"></span>
              </a>

              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <!-- <h4 class="mt-0">Iniciar sesión</h4>
              <p>&nbsp;</p> -->
                <p class="text-muted mb-4">Ingrese su dirección de correo electrónico y contraseña para acceder a la cuenta.</p>
              </div>
                <!--     <a href="index.html">
                        <span><img src="assets/images/logo.jpg" alt="" height="48"></span>
                    </a>
                <p></p> -->



                <!-- title-->


                <!-- form -->
                <form ngNativeValidate [formGroup]="forma" (ngSubmit)="ingresar()">
                    <div class="form-group">
                        <label for="email">Dirección de correo electrónico</label>
                        <input  class="form-control" formControlName="email" type="email" id="email" name="email" required placeholder="Introduce tu correo electrónico" value="test@test.com">
                    </div>
                    <div class="form-group">
                      <a href="javascript:void(0);" (click)="mostrarReestablecerContrasena(false)" class="text-muted float-right"><small>¿Olvidaste tu contraseña?</small></a>
                        <label for="password">contraseña</label>
                        <div class="input-group">
                          <!-- <input required formControlName="rcontrasena" name="rcontrasena" type="password" class="form-control form-control-sm" id="rcontrasena"  placeholder="Password"> -->
                          <!-- <input class="form-control" formControlName="contrasena" type="password" required id="contrasena" name="contrasena" placeholder="Ingresa tu contraseña" value="test@test.com"> -->
                          <!-- <label for="rcontrasena" class="col-form-label">Contraseña</label> -->

                          <div class="input-group">
                            <input required formControlName="contrasena" name="contrasena" [type]="tipo" class="form-control form-control-sm" id="rcontrasena"  placeholder="Contraseña">
                            <div class="input-group-append " data-password="false" (click)="verContasena()">
                              <div class="input-group-text form-control-sm">
                                <span class="uil uil-eye"></span>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="input-group-append" style="padding-right: auto;">
                            <button id="show_password" class="btn btn-primary form-control text-white" type="button" onclick="mostrarPassword()"> <span class="dripicons-preview"></span> </button>
                          </div> -->
                        </div>

                    </div>

                    <div class="form-group mb-0 text-center">
                        <button [disabled]="deshabilitarLogin" type="submit" class="btn btn-primary btn-block"  > <!--type="button" onclick="location.href='/#admin'" -->
                          <span *ngIf="deshabilitarLogin==true" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"> </span>
                          <i class="{{icon_login}}"></i> Iniciar sesión </button>
                          
                    </div>

                </form>

                <div class="text-center">
                  <p></p>

                  <p class="text-muted">¿No tienes una cuenta? <a  [routerLink]="['/register']" class="text-muted ml-1"><b>Regístrate</b></a></p>
                </div>

                <!-- end form-->

                <!-- Footer-->
                <!-- <footer class="footer footer-alt">

                </footer> -->
                <p></p>
            </div> <!-- end .card-body -->
        </div> <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->

    <!-- Auth fluid right content -->
    <!-- background: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)),
                url('./assets/images/background.jpg'); -->
    <div class="text-center mostrar" style="background-color:rgb(57, 151, 189); padding-top: auto; padding-bottom: auto;"> <!-- auth-fluid-right -->
      <img src="./assets/images/login.jpg" alt="Plataforma virtual" class="bg">
        <div class="auth-user-testimonial">
            <!-- <h2 class="mb-3">Tu ruta al éxito</h2>
            <p class="lead"><i class="mdi mdi-format-quote-open"></i> ¡Una herramienta digital que te impulsa! <i class="mdi mdi-format-quote-close"></i>
            </p> -->
        </div> <!-- end auth-user-testimonial-->
    </div>
    <!-- end Auth fluid right content -->
    <app-footer></app-footer>
  </div>
  <!-- end auth-fluid-->




  <!-- Warning Alert Modal -->
<div id="info-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
      <div class="modal-content">
          <div class="modal-body p-4">
              <div class="text-center">
                  <i class="dripicons-information h1 text-info"></i>
                  <h4 class="mt-2">{{titulo_error}}</h4>
                  <p class="mt-3">{{descripcion_error}}</p>
                  <button type="button" class="btn btn-info my-2" data-dismiss="modal">Continuar</button>
              </div>
          </div>
      </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

