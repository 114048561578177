export class usuarioModelo {
  constructor(
    public usuario_id?:string,
    public identidad?:string,
    public nombre_completo?:string,
    public telefono?:string,
    public telefono_alterno?:string,
    public email?:string,
    public contrasena?:string,
    public nombre_empresa?:string,
    public tipo_solicitud?:string,
    public motivo_solicitud?:string,
    public usuario_tipo?:string,
    public municipio_id?:number,
    public aceptatyc?:boolean,
    public imagen_profile?:string,
    public fecha_registro?:string,
    public asesor_id?:string,
    public habilitar_subir_archivos?:string,

    public validar_eventos?:string,
    public genero?:string,
  ){}
}

export class archivoUsuarioModelo {
  constructor(
    public documento_id?:string,
    public usuario_id?:string,
    public archivo_nombre?:string,
    public archivo_nombre_subida?:string,
    public extencion?:string,
    public tipo?:string,
    public peso?:string,
    public fecha_subida?:string
  ){}
}

/* public token?:string, */
