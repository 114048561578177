import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen.pipe';
import { TruefalsePipe } from './truefalse.pipe';
import { DomseguroPipe } from './domseguro.pipe';


@NgModule({
  declarations: [
    ImagenPipe,
    TruefalsePipe,
    DomseguroPipe
  ],
  imports: [],
  exports: [ImagenPipe,TruefalsePipe,DomseguroPipe]
})
export class PipesModule { }
