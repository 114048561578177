import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { UsuarioService } from '../service/usuario.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { usuarioModelo } from '../model/usuario.model';

import Swal from 'sweetalert2';
import { ICON_LOGIN } from '../config/iconos';

declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  forma: FormGroup;
  titulo_error:string="Defina titulo";
  descripcion_error:string="Defina descripción de error";
  email:string="";

  tipo:string="password";

  deshabilitarLogin:boolean=false;
  deshabilitarReestablecer:boolean=false;

  mostrarLogin:boolean=true;

  icon_login = ICON_LOGIN;

  constructor(
    public router: Router,
    public _usuarioService: UsuarioService
    ) { 
      this.forma=new FormGroup({
        email: new FormControl( '', Validators.required ),
        contrasena: new FormControl( '', Validators.required )
      })
    }

  ngOnInit(): void {
    
  }

  verContasena(){
    if(this.tipo=="text"){
      this.tipo="password";
      return
    }
    if(this.tipo=="password"){
      this.tipo="text";
      return;
    }
  }

  mostrarReestablecerContrasena(respuesta:boolean){
    this.mostrarLogin=respuesta;
    this.deshabilitarReestablecer=false;
  }

  reestablecerContrasena(){
    this.deshabilitarReestablecer=true;
    this._usuarioService.reestablecerContrasena(this.email)
    .subscribe((resp:any)=>{
      if(resp._error == true){
        console.log('reset password fallo');
        this.deshabilitarReestablecer=false;
        Swal.fire({
          imageUrl: 'assets/images/logo_mini.png',
          title: resp._titulo,
          html: resp._descripcion,
          focusConfirm: true,
          confirmButtonText: 'Aceptar',
        });
        return;
      }else{
        //this.mostrarReestablecerContrasena(true);
        console.log('reset password ok');
        this.mostrarLogin = true;
        
        //this.mostrarLogin = !this.mostrarLogin;
        Swal.fire({
          imageUrl: 'assets/images/logo_mini.png',
          title: resp._titulo + '.',
          html: resp._descripcion,
          focusConfirm: true,
          confirmButtonText: 'Aceptar',
        });
        return;
      }

      
      

    },error=>{
      this.deshabilitarReestablecer=false;
      Swal.fire({
        imageUrl: 'assets/images/logo_mini.png',
        title: 'Error de inicio de sesión' ,
        text: 'Se produjo un error al querer iniciar sesión',
        focusConfirm: true,
        confirmButtonText: 'Aceptar',
      });
      this.deshabilitarLogin=false;
      return;
    })
  }

  ingresar(){
    this.deshabilitarLogin=true;
    if (!this.forma.valid){ // ó this.forma.invalid
      /* this.titulo_error="Debe ingresar usuario y contraseña";
      this.descripcion_error="Es necesario completar todos los campos"; */
      Swal.fire({
        imageUrl: 'assets/images/logo_mini.png',
        title: "Debe ingresar usuario y contraseña" ,
        html: "Es necesario completar todos los campos",
        focusConfirm: true,
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    let usuario = new usuarioModelo ('','','','','',this.forma.value.email,this.forma.value.contrasena,'','','','');



    this._usuarioService.login(usuario)
    .subscribe((resp:any)=>{
      
      if(resp._error==false){
        this._usuarioService.guardarStorage(resp._token,resp._contenido[0]);
        this.router.navigate(['/inicio/principal']);

        if(resp._eventos_pendientes){
          Swal.fire({
            imageUrl: 'assets/images/logo_mini.png',
            title: 'Usted tiene reunión virtual de asistencia técnica agendada',
            html: resp._fecha_pendiente + `
                  <p>Haga clic en el siguiente link para ver la información:</p>`,
            showCancelButton: true,
            cancelButtonColor: '#7c8996',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#f39c1c',
            confirmButtonText: 'Ir a eventos agendados'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/inicio/asistenciatecnica/agendados']);
            }
          })
        }

        if(resp._eventos_vencidos){
          Swal.fire({
            title: 'La reunión agendada para su solicitud de asistencia técnica ha vencido' ,
            text:  'En caso de requerir la asistencia, se le solicita generar una nueva solicitud mediante el módulo de asistencia técnica',
            focusConfirm: true,
            confirmButtonText: 'Aceptar',
            imageUrl: 'assets/images/logo_mini.png'
          });
        }


      }else{
        this.deshabilitarLogin=false;
        Swal.fire({
          title: resp._titulo ,
          text: resp._descripcion,
          focusConfirm: true,
          confirmButtonText: 'Aceptar',
          imageUrl: 'assets/images/logo_mini.png'
        });
        return;
      }
    },error => {
      this.deshabilitarLogin=false;
      Swal.fire({
        imageUrl: 'assets/images/logo_mini.png',
        title: 'Error de inicio de sesión' ,
        text: 'Se produjo un error al querer iniciar sesión',
        focusConfirm: true,
        confirmButtonText: 'Aceptar',
      });
      return;
    })
  }

}
