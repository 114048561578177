import { Pipe, PipeTransform } from '@angular/core';
/* import { URL_APP, URL_IMAGE_PROFILE_FOLDER, URL_IMAGE_PROFILE_DEFAULT } from 'src/app/config/config'; */
import { URL_DOWNLOAD, URL_IMAGES } from '../../../config/config';


@Pipe({
  name: 'imagen'
})
export class ImagenPipe implements PipeTransform {

  transform(img:string, tipo:string): any {
    let url:string;

    if(tipo=='1'){
      url = URL_IMAGES + 'profile';
      if(!img){
        url+= 'default.png';
      }else{
        url+=img;
      }
      return url;
    }

    if(tipo=='2'){
      url = URL_IMAGES + 'area/curso/';

      if(!img){
        url+='default.png';
      }else{
        url+=img;
      }
      return url;
    }

    if(tipo=='5'){
      url = URL_IMAGES + 'area/curso/modulo/leccion/tema/imagen/';

      if(!img){
        url+='default.png';
      }else{
        url+=img;
      }
      return url;
    }

    if(tipo=='10'){
      url = URL_IMAGES + 'webinar/';

      if(!img){
        url+='webinar-default.webp';
      }else{
        url+=img;
      }
      return url;
    }

    if(tipo=='11'){
      url = URL_IMAGES + 'eventos/';

      if(!img){
        url+='evento-default.webp';
      }else{
        url+=img;
      }
      return url;
    }

    if(tipo=='12'){
      url = URL_IMAGES + 'area/';

      if(!img){
        url+='evento-default.webp';
      }else{
        url+=img;
      }
      return url;
    }



    if(tipo=='20'){  //Archivos de perfil de usuario
      url = URL_DOWNLOAD + 'assets/docs/perfil';
      url+=img;
      return url;
    }

    ////////////////////////////////////////////////////
    //
    //        PARA ADMINISTRACION
    //
    ////////////////////////////////////////////////////
    if(tipo=='50'){  // IMAGEN DEL ASESOR
      url = URL_IMAGES + 'profile_asesor/';
      if(!img){
        url+='default.png';
      }else{
        url+=img;
      }
      return url;
    }

    if(tipo=='51'){  // IMAGEN DEL USUARIO
      url =URL_IMAGES + 'profile/';
      if(!img){
        url+='default.png';
      }else{
        url+=img;
      }
      return url;
    }

    ////////////////////////////////////////////////////
      //
      //        PARA AREA ASISTENCIA TECNICA
      //
      ////////////////////////////////////////////////////
      if(tipo=='60'){  // IMAGEN DE ASISTENCIA TECNICA
        url =URL_IMAGES + 'asistenciatecnica/';
        if(!img){
          url+='default.png';
        }else{
          url+=img;
        }
        return url;
      }



      ////////////////////////////////////////////////////
      //
      //        PARA ATENCION EMPRENDIMIENTO
      //
      ////////////////////////////////////////////////////
      // PLANO DE ASISTENCIA TECNICA
      if(tipo=='70'){
        url = URL_DOWNLOAD + 'assets/atencionemprendimiento/analisistecnico/plano/';
        if(!img){
          url+='';
        }else{
          url+=img;
        }
        return url;
      }

      // IMAGEN DE EVIDENCIA MODULO MERCADO
      if(tipo=='71'){
        url = URL_DOWNLOAD + 'assets/atencionemprendimiento/mercado/evidencia/';
        if(!img){
          url+='';
        }else{
          url+=img;
        }
        return url;
      }

  }

}
